.progress-page {
  max-width: 80%;
  margin: 2rem auto;
  padding: 1.5rem;
}

.progress-page h2 {
  color: var(--text-primary);
  margin-bottom: 2rem;
  font-size: 1.5rem;
}

.selection {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.search-container {
  position: relative;
  flex: 1;
}

.search-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  font-size: 0.875rem;
  background-color: white;
}

.search-input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  margin-top: 0.25rem;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.dropdown-item {
  padding: 0.75rem;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: var(--background-light);
}

.records {
  background-color: var(--background-light);
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  padding: 1.5rem;
}

.records h3 {
  color: var(--text-primary);
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.records ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.records li {
  padding: 1rem;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
}

.records li:last-child {
  border-bottom: none;
}

.records p {
  color: var(--text-secondary);
  text-align: center;
  padding: 1rem;
  font-size: 0.875rem;
}

/* Add these variables to :root if not already present */
:root {
  --text-primary: #333;
  --text-secondary: #666;
  --border-color: #ddd;
  --background-light: #f8f9fa;
  --primary-color: #007bff;
}

/* Add mobile-specific styles */
@media (max-width: 480px) {
  /* Container adjustments */
  .progress-page {
    max-width: 100%;
    margin: 1rem auto;
    padding: 0.75rem;
  }

  /* Header adjustments */
  .progress-page h2 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  /* Selection area adjustments */
  .selection {
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  .chart-container {
    min-height: 300px; /* Adjust this value as needed */
    width: 100%;
    margin: 1.5rem 0;
  }

  /* Search input adjustments */
  .search-container {
    width: 100%;
  }

  .search-input {
    width: 80%;
    padding: 0.5rem;
    font-size: 0.875rem;
  }

  /* Dropdown adjustments */
  .dropdown {
    width: 80%;
    max-height: 180px;
  }

  .dropdown-item {
    padding: 0.5rem;
    font-size: 0.875rem;
  }

  /* Records section adjustments */
  .records {
    padding: 1rem;
    border-radius: 0.25rem;
  }

  .records h3 {
    font-size: 1.1rem;
    text-align: center;
  }

  .records li {
    padding: 0.75rem;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
    text-align: left;
  }

  .records p {
    padding: 0.75rem;
    font-size: 0.8rem;
  }
}

/* Chart container styles */
.chart-container {
  width: 100%;
  height: auto;
  margin: 1rem 0;
}

@media (max-width: 480px) {
  .chart-container {
    min-height: 300px; /* Adjust this value as needed */
    width: 100%;
    margin: 1.5rem 0;
  }
}