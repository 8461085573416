.metric-history {
  margin-top: 2rem;
  padding: 1rem;
}

.metric-history h3 {
  margin-bottom: 1rem;
  color: #333;
}

.metric-history-table {
  overflow-x: auto;
}

.metric-history-table table {
  width: 100%;
  border-collapse: collapse;
  min-width: 800px;
}

.metric-history-table th,
.metric-history-table td {
  padding: 0.75rem;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.metric-history-table th {
  background-color: #f5f5f5;
  font-weight: 600;
  color: #333;
}

.metric-history-table tr:hover {
  background-color: #f9f9f9;
}

.metric-history-table td {
  color: #666;
}

/* Add these styles for number input compatibility */
input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
} 