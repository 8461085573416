.admin-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.date-selector {
  margin: 20px 0;
  text-align: center;
  
}

.workouts-grid {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.workout-plan-card {
  width: 100%;
  background: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.client-info {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.client-info h3 {
  margin: 0 0 5px 0;
}

.client-email, .trainer-email {
  display: block;
  color: #666;
  font-size: 0.9em;
  margin-bottom: 5px;
}

.status {
  display: inline-block;
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 0.8em;
  margin-top: 5px;
}

.status.completed {
  background: #e6ffe6;
  color: #006600;
}

.status.pending {
  background: #fff3e6;
  color: #cc7700;
}

.workout-details {
  margin-top: 15px;
}

.details-header {
  display: grid;
  grid-template-columns: 2fr repeat(4, 1fr);
  font-weight: bold;
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}

.exercise-row {
  display: grid;
  grid-template-columns: 2fr repeat(4, 1fr);
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}

.exercise-name {
  font-weight: 500;
}

.muscle-group {
  color: #666;
  font-size: 0.9em;
  margin-left: 5px;
}

.workout-notes {
  margin-top: 1rem;
  padding: 1rem;
  background-color: var(--background-color, #fff);
  border-radius: 0.375rem;
  border: 1px solid var(--border-color-light, #f0f0f0);
}

.workout-notes h4 {
  margin: 0 0 0.5rem 0;
  color: var(--text-primary, #333);
  font-size: 0.875rem;
  font-weight: 600;
}

.workout-notes p {
  margin: 0;
  white-space: pre-wrap;
  color: var(--text-secondary, #6c757d);
  font-size: 0.875rem;
  line-height: 1.5;
}

.workout-time {
  margin-top: 15px;
  color: #666;
  font-size: 0.9em;
}

.loading, .no-workouts {
  text-align: center;
  padding: 40px;
  color: #666;
}

.trainers-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 30px;
  max-width: 800px;
  margin: 30px auto 0;
}

.trainer-section {
  width: 100%;
  background: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
} 