:root {
  --primary-color: #2196F3;
  --primary-hover: #1976D2;
  --success-color: #4CAF50;
  --error-color: #f44336;
  --warning-color: #ff9800;
  --text-primary: #2c3e50;
  --text-secondary: #606f7b;
  --border-color: #e2e8f0;
  --background-light: #f8fafc;
  --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.1);
  --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
  --transition: all 0.2s ease;
}

/* Container */
.client-info-container {
  max-width: 80%;
  margin: 2rem auto;
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: var(--shadow-md);
}

/* Typography */
h1 {
  color: var(--text-primary);
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 2.5rem;
}

/* Sections */
.form-section {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  border: 1px solid var(--border-color);
}

.form-section h2 {
  color: var(--text-primary);
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid var(--primary-color);
}

/* Form Groups */
.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  color: var(--text-primary);
  font-weight: 500;
  margin-bottom: 0.5rem;
}

/* Inputs */
.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 1rem;
  color: var(--text-primary);
  background: white;
  transition: var(--transition);
}

.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.1);
}

.form-group textarea {
  min-height: 100px;
  resize: vertical;
}

/* Checkboxes */
.checkbox-group {
  background: white;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  padding: 1rem;
  margin-bottom: 0.75rem;
  transition: var(--transition);
}

.checkbox-group:hover {
  background: var(--background-light);
}

.checkbox-group label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin: 0;
  cursor: pointer;
}

.checkbox-group span {
  flex: 1;
  color: var(--text-primary);
  font-size: 0.95rem;
  line-height: 1.5;
}

.checkbox-group input[type="checkbox"] {
  width: 1.25rem;
  height: 1.25rem;
  margin: 0;
  flex-shrink: 0;
  cursor: pointer;
  accent-color: var(--primary-color);
}

/* Submit Button */
.submit-btn {
  width: 100%;
  padding: 1rem;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition);
  margin-top: 2rem;
  justify-content: center;
}

.submit-btn:hover {
  background: var(--primary-hover);
  transform: translateY(-1px);
  box-shadow: var(--shadow-sm);
}

.submit-btn:active {
  transform: translateY(0);
}

/* Required Fields */
.required::after {
  content: "*";
  color: var(--error-color);
  margin-left: 4px;
}

/* Responsive */
@media (max-width: 768px) {
  .client-info-container {
    margin: 1rem;
    padding: 1rem;
  }

  h1 {
    font-size: 1.5rem;
  }
}

/* Print */
@media print {
  .client-info-container {
    box-shadow: none;
    margin: 0;
    padding: 0;
  }

  .form-section {
    break-inside: avoid;
  }

  .submit-btn {
    display: none;
  }
}

/* Search Section Styles */
.search-section {
  margin-bottom: 2rem;
}

.search-container {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.search-input {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 1rem;
}

.search-btn,
.new-client-btn {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition);
}

.search-btn {
  background: var(--primary-color);
  color: white;
}

.new-client-btn {
  background: var(--success-color);
  color: white;
}

.search-results {
  margin-top: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  overflow: hidden;
}

.client-result {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.client-result:last-child {
  border-bottom: none;
}

.client-result:hover {
  background-color: var(--background-light);
}

.reset-btn {
  margin-left: 1rem;
  background-color: var(--error-color);
  color: white;
}

/* Add mobile-specific styles */
@media (max-width: 480px) {
  /* Container adjustments */
  .client-info-container {
    max-width: 100%;
    margin: 0.5rem;
    padding: 1rem;
  }

  /* Typography adjustments */
  h1 {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
  }

  /* Form section adjustments */
  .form-section {
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .form-section h2 {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }

  /* Form group adjustments */
  .form-group {
    margin-bottom: 1rem;
  }

  /* Input adjustments */
  .form-group input,
  .form-group textarea,
  .form-group select {
    width: 90%;
    padding: 0.3rem;
    font-size: 0.9rem;
  }

  /* Checkbox group adjustments */
  .checkbox-group {
    padding: 0.75rem;
  }

  .checkbox-group span {
    font-size: 0.9rem;
  }

  /* Search section adjustments */
  .search-container {
    flex-direction: column;
    gap: 0.5rem;
  }

  .search-input {
    padding: 0.5rem;
    font-size: 0.9rem;
  }

  .search-btn,
  .new-client-btn,
  .reset-btn {
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }

  /* Search results adjustments */
  .client-result {
    padding: 0.75rem;
    flex-direction: column;
    gap: 0.5rem;
  }

  /* Submit button adjustments */
  .submit-btn {
    padding: 0.75rem;
    margin-top: 1.5rem;
    font-size: 0.9rem;
  }
}