.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 2rem;
  background-color: var(--background-color, #f8f9fa);
  padding-top: 5rem;
}

.login-card {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.login-header {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--text-primary, #333);
  font-size: 1.5rem;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group input {
  padding: 0.75rem 1rem;
  border: 1px solid var(--border-color, #dee2e6);
  border-radius: 0.375rem;
  font-size: 1rem;
  transition: border-color 0.2s ease;
}

.form-group input:focus {
  outline: none;
  border-color: var(--primary-color, #007bff);
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.1);
}

.login-button {
  background-color: var(--primary-color, #007bff);
  color: white;
  padding: 0.75rem;
  border: none;
  border-radius: 0.375rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.login-button:hover {
  background-color: var(--primary-color-dark, #0056b3);
}

.login-button:disabled {
  background-color: var(--disabled-color, #6c757d);
  cursor: not-allowed;
}

.error-message {
  color: var(--error-color, #dc3545);
  text-align: center;
  font-size: 0.875rem;
  margin-top: 1rem;
  padding: 0.75rem;
  background-color: var(--error-bg, #f8d7da);
  border-radius: 0.375rem;
  border: 1px solid var(--error-border, #f5c2c7);
}

.logo-container {
  text-align: center;
  margin-bottom: 1.5rem;
}

.logo {
  max-width: 600px;
  height: auto;
  margin-bottom: 1rem;
}

@media (max-width: 480px) {
  .logo {
    max-width: 150px;
  }
  
  .login-card {
    padding: 1.5rem;
  }
  .login-container {
    padding-top: 3rem;
  }
} 