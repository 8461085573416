/* Base Variables */
:root {
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;
  
  --border-radius: 4px;
  --border-color: #dee2e6;
  
  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.1);
  
  --color-primary: #4CAF50;
  --color-primary-hover: #45a049;
  --color-danger: #dc3545;
  --color-danger-hover: #c82333;
  --color-edit: #4299e1;
  --color-edit-hover: #3182ce;
  --color-cancel: #6c757d;
  --color-cancel-hover: #5a6268;
  --color-add: #9f7aea;
  --color-add-hover: #805ad5;
  
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;
  --font-size-lg: 1.25rem;
  
  --blue: #4299e1;
  --blue-hover: #3182ce;
  --green: #48bb78;
  --green-hover: #38a169;
  --red: #f56565;
  --red-hover: #e53e3e;
  --purple: #9f7aea;
  --purple-hover: #805ad5;
}

/* Layout Containers */
.exercises-container {
  padding: var(--spacing-xl);
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.exercises-list {
  background-color: white;
  padding: var(--spacing-xl);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-sm);
  width: 100%;
  box-sizing: border-box;
}

/* Header and Controls */
.exercises-header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-lg);
}

.filter-controls {
  display: flex;
  gap: var(--spacing-md);
  align-items: center;
}

/* Form Elements */
.search-input,
.filter-select {
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  font-size: var(--font-size-base);
  min-width: 200px;
}

/* Table Styles */
.exercises-table {
  width: 100%;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
}

.table-header,
.table-row {
  display: grid;
  grid-template-columns: minmax(200px, 2fr) minmax(150px, 1fr) auto;
  gap: var(--spacing-md);
  padding: var(--spacing-md);
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.table-header {
  background-color: #f8f9fa;
  font-weight: 600;
  border-bottom: 2px solid var(--border-color);
}

.table-row {
  border-bottom: 1px solid var(--border-color);
}

.table-row:last-child {
  border-bottom: none;
}

/* Table Columns */
.col-name {
  padding-right: var(--spacing-md);
}

.col-group {
  color: var(--text-secondary);
}

.col-actions {
  display: flex;
  gap: var(--spacing-sm);
  justify-content: flex-end;
  flex-wrap: wrap;
  min-width: fit-content;
}

/* Buttons */
.toggle-form-btn,
.edit-button,
.save-button,
.delete-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: background-color 0.2s;
  color: white;
  white-space: nowrap;
  min-width: fit-content;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Add Exercise Button (Blue) */
.toggle-form-btn {
  background-color: var(--blue) !important;
}

.toggle-form-btn:hover {
  background-color: var(--blue-hover) !important;
}

/* Edit Button (Blue) */
.edit-button {
  background-color: var(--blue) !important;
}

.edit-button:hover {
  background-color: var(--blue-hover) !important;
}

/* Save Button (Green) */
.save-button {
  background-color: var(--green) !important;
}

.save-button:hover {
  background-color: var(--green-hover) !important;
}

/* Delete Button (Red) */
.delete-button {
  background-color: var(--red) !important;
}

.delete-button:hover {
  background-color: var(--red-hover) !important;
}

/* Form Styles */
.add-exercise-form {
  background-color: white;
  padding: var(--spacing-xl);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-sm);
  margin-bottom: var(--spacing-xl);
}

.form-group {
  margin-bottom: var(--spacing-md);
}

.form-group label {
  display: block;
  margin-bottom: var(--spacing-sm);
  font-weight: 500;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  font-size: var(--font-size-base);
}

.form-actions {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: var(--spacing-md);
  width: 100%;
}

.form-actions button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  font-size: var(--font-size-base);
  transition: background-color 0.2s;
}

.form-actions button[type="submit"] {
  background-color: var(--green) !important;
  color: white;
}

.form-actions button[type="submit"]:hover {
  background-color: var(--green-hover) !important;
}

.form-actions button[type="button"] {
  background-color: var(--blue) !important;
  color: white;
}

.form-actions button[type="button"]:hover {
  background-color: var(--blue-hover) !important;
}

/* Responsive Design */
@media (max-width: 768px) {
  .exercises-container {
    padding: var(--spacing-md);
  }

  .filter-controls {
    flex-direction: column;
    width: 100%;
  }

  .search-input,
  .filter-select {
    width: 100%;
  }

  .table-header,
  .table-row {
    grid-template-columns: minmax(150px, 1.5fr) minmax(100px, 1fr) auto;
    gap: 0.5rem;
  }
} 

/* Button Base Styles */
.add-exercise-btn,
.add-pr-btn,
.complete-btn,
.workout-submit-btn,
.toggle-form-btn,
.edit-button,
.save-button,
.delete-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: background-color 0.2s;
  color: white;
  white-space: nowrap;
  min-width: fit-content;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Add Exercise Button (Blue) */
.add-exercise-btn {
  background-color: var(--blue) !important;
}

.add-exercise-btn:hover {
  background-color: var(--blue-hover) !important;
}

/* Add PR Button (Purple) */
.add-pr-btn {
  background-color: var(--purple) !important;
}

.add-pr-btn:hover {
  background-color: var(--purple-hover) !important;
}

/* Mark Complete Button (Green) */
.complete-btn {
  background-color: var(--green) !important;
}

.complete-btn:hover:not(:disabled) {
  background-color: var(--green-hover) !important;
}

/* Save Workout Button (Red) */
.workout-submit-btn {
  background-color: var(--red) !important;
}

.workout-submit-btn:hover:not(:disabled) {
  background-color: var(--red-hover) !important;
}

/* Disabled state for buttons */
.complete-btn:disabled,
.workout-submit-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Add mobile styles */
@media (max-width: 480px) {
  /* Container adjustments */
  .exercises-container {
    padding: var(--spacing-sm);
  }

  .exercises-list {
    padding: var(--spacing-sm);
  }

  /* Table adjustments */
  .table-header,
  .table-row {
    grid-template-columns: 1fr auto;
    padding: 0.5rem;
  }

  /* Hide muscle group on mobile */
  .col-group {
    display: none;
  }

  /* Button adjustments */
  .toggle-form-btn,
  .edit-button,
  .save-button,
  .delete-button {
    padding: 0.4rem 0.6rem;
    font-size: 0.75rem;
    height: 28px;
  }

  .col-actions {
    gap: 0.25rem;
  }

  /* Form adjustments */
  .add-exercise-form {
    padding: var(--spacing-sm);
  }

  .form-group {
    margin-bottom: 0.5rem;
  }

  .form-actions {
    gap: 0.5rem;
    flex-wrap: wrap;
  }

  .form-actions button {
    padding: 0.4rem 0.6rem;
    font-size: 0.75rem;
    flex: 1 1 calc(50% - 0.25rem);
    min-width: auto;
    height: 28px;
  }

  /* Search and filter adjustments */
  .search-input,
  .filter-select {
    padding: 0.5rem;
    min-width: 100%;
    font-size: var(--font-size-sm);
  }
} 

.table-row .col-group select {
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
} 