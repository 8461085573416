.dashboard {
  max-width: 1200px;
  margin: 0 auto;
}

.dashboard-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.g2-logo {
  max-width: 400px;
  height: auto;
}

.workouts-summary {
  margin-bottom: 1rem;
  padding: 2rem;
}

.info-container {
  display: flex;
  gap: 3rem;
  align-items: center;
  background: #f8f9fa;
  padding: 1.5rem 2rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.date-time {
  text-align: center;
}

.time2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
}

.date {
  font-size: 1.1rem;
  color: #666;
}


.weather {
  text-align: center;
  padding-left: 3rem;
  border-left: 2px solid #ddd;
}

.temperature {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
}

.weather-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.weather-description {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 1.1rem;
  color: #666;
  text-transform: capitalize;
}

.weather-icon {
  width: 32px;
  height: 32px;
}

.weather-stats {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.8rem;
  font-size: 0.9rem;
  color: #666;
  text-align: left;
  margin-top: 0.5rem;
}

/* Tablet Styles */
@media screen and (max-width: 768px) {
  .dashboard {
    padding: 1rem;
  }

  .g2-logo {
    max-width: 250px;
  }

  .info-container {
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem;
  }

  .weather {
    padding-left: 0;
    border-left: none;
    border-top: 2px solid #ddd;
    padding-top: 1.5rem;
  }

  .weather-stats {
    grid-template-columns: 1fr;
    text-align: center;
  }
}

/* Mobile Styles */
@media screen and (max-width: 480px) {
  .g2-logo {
    max-width: 200px;
  }

  .time {
    font-size: 2rem;
  }

  .date {
    font-size: 1rem;
  }

  .temperature {
    font-size: 2rem;
  }

  .weather-description {
    font-size: 1rem;
  }

  .weather-stats {
    font-size: 0.9rem;
  }
}