.previous-workouts {
  margin-top: 2rem;
  padding: 1rem;
  background-color: var(--background-color, #fff);
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.previous-workouts h3 {
  margin-bottom: 1rem;
  color: var(--text-primary, #333);
  font-size: 1.25rem;
}

.workouts-container {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  padding-bottom: 1rem;
}

.workout-card {
  flex: 0 0 calc(33.333% - 1rem);
  min-width: 300px;
  background-color: var(--card-background, #f8f9fa);
  border: 1px solid var(--border-color, #dee2e6);
  border-radius: 0.375rem;
  padding: 1rem;
}

.workout-header {
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--border-color, #dee2e6);
}

.workout-header h4 {
  margin: 0;
  color: var(--text-primary, #333);
  font-size: 1rem;
}

.completion-date {
  font-size: 0.875rem;
  color: var(--text-secondary, #6c757d);
}

.exercises-list {
  font-size: 0.875rem;
}

.exercise-header {
  display: grid;
  grid-template-columns: 2fr repeat(4, 1fr);
  gap: 0.5rem;
  padding: 0.5rem 0;
  font-weight: 600;
  border-bottom: 1px solid var(--border-color, #dee2e6);
}

.exercise-row {
  display: grid;
  grid-template-columns: 2fr repeat(4, 1fr);
  gap: 0.5rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--border-color-light, #f0f0f0);
}

.exercise-name {
  font-weight: 500;
}

.muscle-group {
  font-size: 0.75rem;
  color: var(--text-secondary, #6c757d);
  margin-left: 0.25rem;
}

.exercise-detail {
  text-align: center;
}

/* Workout Notes Styles */
.workout-notes {
  margin-top: 1rem;
  padding: 1rem;
  background-color: var(--background-color, #fff);
  border-radius: 0.375rem;
  border: 1px solid var(--border-color-light, #f0f0f0);
}

.workout-notes h5 {
  margin: 0 0 0.5rem 0;
  color: var(--text-primary, #333);
  font-size: 0.875rem;
  font-weight: 600;
}

.workout-notes p {
  margin: 0;
  white-space: pre-wrap;
  color: var(--text-secondary, #6c757d);
  font-size: 0.875rem;
  line-height: 1.5;
}

/* Add these CSS variables to your root */
:root {
  --background-color: #fff;
  --card-background: #f8f9fa;
  --border-color: #dee2e6;
  --border-color-light: #f0f0f0;
  --text-primary: #333;
  --text-secondary: #6c757d;
}

.previous-workouts-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.history-toggle-btn {
  padding: 0.5rem 1rem;
  background-color: var(--card-background, #f8f9fa);
  border: 1px solid var(--border-color, #dee2e6);
  border-radius: 0.375rem;
  color: var(--text-primary, #333);
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.history-toggle-btn:hover {
  background-color: var(--border-color, #dee2e6);
}

.workouts-container.history-view {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  max-height: 80vh;
}

.workouts-container.history-view .workout-card {
  flex: 0 0 100%;
  min-width: 100%;
} 