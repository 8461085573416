.plans-container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .plans-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .plans-header h2 {
    margin: 0;
    color: var(--text-primary);
  }
  
  .date-picker {
    padding: 0.5rem;
    border: 1px solid var(--border-color);
    border-radius: 0.25rem;
    font-size: 1rem;
  }
  
  .no-workouts {
    text-align: center;
    padding: 2rem;
    background-color: var(--background-light);
    border-radius: 0.5rem;
    color: var(--text-secondary);
  }
  
  .workouts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 1.5rem;
  }
  
  .workout-plan-card {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 1.5rem;
  }
  
  .client-info {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--border-color);
    position: relative;
  }
  
  .client-info h3 {
    margin: 0 0 0.5rem 0;
    color: var(--text-primary);
  }
  
  .client-email {
    display: block;
    font-size: 0.875rem;
    color: var(--text-secondary);
  }
  
  .status {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
    font-size: 0.75rem;
    font-weight: 500;
  }
  
  .status.completed {
    background-color: var(--success-light);
    color: var(--success);
  }
  
  .status.pending {
    background-color: var(--warning-light);
    color: var(--warning);
  }
  
  .workout-details {
    margin-bottom: 1rem;
  }
  
  .details-header {
    display: grid;
    grid-template-columns: 2fr repeat(4, 1fr);
    gap: 0.5rem;
    padding: 0.5rem 0;
    font-weight: 600;
    border-bottom: 1px solid var(--border-color);
    font-size: 0.875rem;
  }
  
  .exercise-row {
    display: grid;
    grid-template-columns: 2fr repeat(4, 1fr);
    gap: 0.5rem;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--border-color-light);
    font-size: 0.875rem;
  }
  
  .exercise-name {
    font-weight: 500;
  }
  
  .muscle-group {
    font-size: 0.75rem;
    color: var(--text-secondary);
    margin-left: 0.25rem;
  }
  
  .detail-col {
    text-align: center;
  }
  
  .workout-time {
    margin-top: 1rem;
    padding-top: 0.5rem;
    border-top: 1px solid var(--border-color-light);
    font-size: 0.875rem;
    color: var(--text-secondary);
  }
  
  .workout-notes {
    margin-top: 1rem;
    padding: 1rem;
    background-color: var(--background-light, #f8f9fa);
    border-radius: 0.5rem;
    border: 1px solid var(--border-color, #dee2e6);
  }
  
  .workout-notes h4 {
    margin: 0 0 0.5rem 0;
    font-size: 0.9rem;
    color: var(--text-secondary, #6c757d);
    font-weight: 600;
  }
  
  .workout-notes p {
    margin: 0;
    font-size: 0.875rem;
    line-height: 1.5;
    white-space: pre-wrap; /* This preserves line breaks in the notes */
    color: var(--text-primary, #212529);
  }
  
  :root {
    --text-primary: #333;
    --text-secondary: #666;
    --border-color: #dee2e6;
    --border-color-light: #f0f0f0;
    --background-light: #f8f9fa;
    --success: #28a745;
    --success-light: #d4edda;
    --warning: #ffc107;
    --warning-light: #fff3cd;
  }