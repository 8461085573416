.client-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.view-toggle {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.toggle-btn {
  padding: 0.75rem 1.5rem;
  border: 2px solid #4a90e2;
  background: none;
  color: #4a90e2;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 120px;
}

.toggle-btn:hover {
  background-color: rgba(74, 144, 226, 0.1);
}

.toggle-btn.active {
  background-color: #4a90e2;
  color: white;
}

.view-content {
  width: 100%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .view-toggle {
    gap: 0.5rem;
  }

  .toggle-btn {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    min-width: 100px;
  }
} 