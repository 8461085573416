/* Layout */
.workout-table {
  margin: 1rem 0;
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  overflow: visible;

}

.workout-row {
  display: grid;
  grid-template-columns: 1fr 0.4fr 0.4fr 0.4fr 0.4fr 0.6fr;
  gap: 1.5rem;
  padding: 1rem;
  border-bottom: 1px solid var(--border-color);
  align-items: center;

}

/* Column Sizes */
.col {
  padding: 0 0.3125rem;
}

.col.exercise {
  position: relative;
  width: 100%;
  min-width: 100px;
}

.col.sets,
.col.reps,
.col.time,
.col.weight {
  width: 100%;
  min-width: 40px;
}

.col.muscle-group {
  width: 100%;
  min-width: 60px;
}

/* Headers */
.workout-row.header {
  background-color: var(--background-light);
  font-weight: 600;
  border-bottom: 2px solid var(--border-color);
}

/* Form Elements */
.col input,
.muscle-group-display {
  width: 70%;
  padding: 0.375rem 0.5rem;
  font-size: 0.875rem;
  border: 1px solid var(--border-color, #ddd);
  border-radius: 0.25rem;
}

.muscle-group-display {
  background-color: var(--input-bg, #f8f9fa);
  min-height: 2.3125rem;
  display: flex;
  align-items: center;
}

/* Dropdown */
.exercise-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  z-index: var(--z-exercise-dropdown);
}

.exercise-option {
  padding: 8px 12px;
  cursor: pointer;
  background-color: white;
}

.exercise-option:hover {
  background-color: #f5f5f5;
}

/* Submit Button */
.workout-submit-btn {
  margin: 0 auto;
  padding: 0.625rem 1.25rem;
  background-color: var(--primary-color, #007bff);
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
  width: auto;
  display: block;
}

.workout-submit-btn:hover {
  background-color: var(--primary-color-dark, #0056b3);
}

/* Header Section */
.workout-header {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.client-search {
  position: relative;
  flex: 2;
}

.date-picker-container {
  flex: 1;
  position: relative;
  z-index: 1002;
}

/* Search and Date Inputs */
.client-search-input,
.date-picker {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--border-color, #ddd);
  border-radius: 0.25rem;
  font-size: 0.875rem;
}

/* Client Dropdown */
.client-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid var(--border-color, #ddd);
  border-radius: 0.25rem;
  max-height: 12.5rem;
  overflow-y: auto;
  z-index: 1001;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.client-option {
  padding: 0.5rem 0.75rem;
  cursor: pointer;
}

.client-option:hover {
  background-color: var(--hover-bg, #f5f5f5);
}

/* Client Info Display */
.client-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.client-details {
  flex: 1;
}

.client-prs {
  flex: 1;
  padding-left: 2rem;
  border-left: 1px solid #ddd;
}

.pr-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.pr-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.pr-label {
  font-weight: bold;
  color: #555;
  margin-right: 1rem;
}

.pr-value {
  color: #2c5282;
  font-weight: 500;
}

.client-info h3 {
  margin: 0 0 1rem 0;
  color: #2d3748;
}

/* Override react-datepicker styles */
.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--border-color, #ddd);
  border-radius: 0.25rem;
  font-size: 0.875rem;
}

/* Container width */
.workout-form {
  max-width: 80%;
  margin: 0 auto;
  padding: 1.5rem;
  position: relative;
  z-index: 1;
}
.add-pr-btn {
  padding: 0.625rem 1.25rem;
  background-color: var(--danger-color, #dc3545);
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.add-pr-btn:hover {
  background-color: var(--danger-color-dark, #c82333);
}

/* Add these variables to :root if not already present */
:root {
  --blue: #4299e1;
  --blue-hover: #3182ce;
  --green: #48bb78;
  --green-hover: #38a169;
  --red: #f56565;
  --red-hover: #e53e3e;
  --purple: #9f7aea;
  --purple-hover: #805ad5;
  /* Distinct colors for each button */
  --add-exercise-color: #4299e1;    /* Blue */
  --add-exercise-hover: #3182ce;
  
  --add-pr-color: #9f7aea;          /* Purple */
  --add-pr-hover: #805ad5;
  
  --complete-color: #48bb78;         /* Green */
  --complete-hover: #38a169;
  
  --save-color: #f56565;            /* Red */
  --save-hover: #e53e3e;
  --z-base: 1;
  --z-dropdown: 1000;
  --z-datepicker: 1100;
  --z-exercise-dropdown: 1200;
}

/* Rest of your existing CSS */
/* Exercise Search and Dropdown */
.col.exercise {
  position: relative;
  width: 100%;
}

.col.exercise input {
  position: relative;
  z-index: var(--z-base);
  width: 100%;
}

.exercise-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  z-index: var(--z-exercise-dropdown);
}

.exercise-option {
  padding: 8px 12px;
  cursor: pointer;
  background-color: white;
}

.exercise-option:hover {
  background-color: #f5f5f5;
}

/* Ensure dropdowns appear above other elements */
.client-dropdown,
.exercise-dropdown {
  z-index: 1001;
}

/* Ensure proper positioning for all relative elements */
.client-search,
.col.exercise {
  position: relative;
}

/* Form Actions */
.form-actions {
  display: flex;
  gap: 2rem;
  margin: 2rem 0;
  align-items: center;
  justify-content: center;
}

/* Button Base Styles */
.add-exercise-btn,
.add-pr-btn,
.complete-btn,
.workout-submit-btn {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: background-color 0.2s;
  color: white;
  min-width: 120px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Add Exercise Button (Blue) */
.add-exercise-btn {
  background-color: var(--blue) !important;
}

.add-exercise-btn:hover {
  background-color: var(--blue-hover) !important;
}

/* Add PR Button (Purple) */
.add-pr-btn {
  background-color: var(--purple) !important;
}

.add-pr-btn:hover {
  background-color: var(--purple-hover) !important;
}

/* Mark Complete Button (Green) */
.complete-btn {
  background-color: var(--green) !important;
}

.complete-btn:hover:not(:disabled) {
  background-color: var(--green-hover) !important;
}

/* Save Workout Button (Red) */
.workout-submit-btn {
  background-color: var(--red) !important;
}

.workout-submit-btn:hover:not(:disabled) {
  background-color: var(--red-hover) !important;
}

/* Disabled state for buttons */
.complete-btn:disabled,
.workout-submit-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.workout-notes {
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: var(--background-light);
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
}

.workout-notes h4 {
  margin: 0 0 0.75rem 0;
  font-size: 1rem;
  color: var(--text-primary);
}

.notes-textarea {
  width: 90%;
  min-height: 100px;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.5;
  resize: vertical;
}

.notes-textarea:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

/* PR Container and Button Styles */
.pr-container {
  margin: 1.5rem 0;
  padding: 1.5rem;
  background-color: var(--background-light);
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
}

.pr-container h4 {
  margin: 0 0 1rem 0;
  font-size: 1rem;
  color: var(--text-primary);
}

.pr-row {
  display: grid;
  grid-template-columns: 2fr 0.6fr 0.6fr 0.8fr;
  gap: 2rem;
  align-items: center;
}

.pr-exercise-dropdown {
  width: 100%;
  padding: 0.375rem 0.5rem;
  font-size: 0.875rem;
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  background-color: white;
}

.pr-weight-input,
.pr-reps-input {
  width: 100%;
  padding: 0.375rem 0.5rem;
  font-size: 0.875rem;
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
}

/* Button Styles */
.pr-container .submit-btn {
  padding: 0.625rem 1.25rem;
  background-color: var(--danger-color, #dc3545); /* Changed to red */
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
  width: 100%;
}

.pr-container .submit-btn:hover {
  background-color: var(--danger-color-dark, #c82333); /* Changed to darker red */
}

.pr-container .submit-btn:disabled {
  opacity: 0.65;
  cursor: not-allowed;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .workout-row {
    grid-template-columns: 1.2fr 0.5fr 0.5fr 0.5fr 0.5fr 0.7fr;
    gap: 2rem;
    padding: 0.75rem;
  }

  .col.exercise {
    min-width: 80px;
    margin-right: 1rem;
  }

  .col.sets,
  .col.reps,
  .col.time,
  .col.weight {
    min-width: 35px;
  }

  .col.muscle-group {
    min-width: 50px;
  }

  .col input,
  .muscle-group-display {
    width: 85%;
    padding: 0.3rem 0.4rem;
  }
}

@media (max-width: 768px) {
  .pr-container {
    padding: 1rem;
  }

  .pr-row {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .pr-exercise-dropdown,
  .pr-weight-input,
  .pr-reps-input,
  .pr-container .submit-btn {
    margin-bottom: 0.75rem;
    width: 100%;
  }

  .pr-container .submit-btn {
    margin-top: 0.25rem;
  }
}

/* When row is active (has dropdown open), increase its z-index */
.workout-row.active {
  position: relative;
  z-index: var(--z-dropdown);
}

/* React DatePicker specific styles */
.react-datepicker-popper {
  z-index: 1003 !important;
}

.react-datepicker-wrapper {
  position: relative;
  z-index: 1002;
}

.react-datepicker__portal {
  z-index: 1003 !important;
}

/* Ensure the date picker input is also above other elements */
.date-picker {
  position: relative;
  z-index: 1002;
}

/* Add new mobile-specific styles */
@media (max-width: 480px) {
  .workout-form {
    max-width: 100%;
    padding: 0.75rem 0;
  }

  .workout-table {
    margin: 0.5rem 0;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }

  .workout-row {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 2px solid var(--border-color);
  }

  .workout-row.header {
    display: none;
  }

  .col {
    position: relative;
    width: 100%;
    max-width: none;
    display: grid;
    grid-template-columns: 80px 1fr;
    align-items: center;
    margin: 0;
    padding: 0 0.5rem;
  }

  .exercise-dropdown {
    width: calc(100% - 80px);
    left: 80px;
    right: 0;
  }

  .workout-row:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  /* Make exercise search full width */
  .col.exercise {
    width: 100%;
    grid-template-columns: 1fr;
    margin-bottom: 1rem;
  }

  .col.exercise::before {
    margin-bottom: 0.5rem;
  }

  .col.exercise input {
    width: 80%;
  }

  /* Create two-column layout for sets, reps, weight, time */
  .workout-row {
    position: relative;
  }

  .col:not(.exercise):not(.muscle-group) {
    width: calc(40%);
    display: inline-block;
    vertical-align: top;
    margin-bottom: 0.2rem;
  }

  /* Adjust exercise dropdown to match new layout */
  .exercise-dropdown {
    width: 100%;
    left: 0;
  }

  /* Updated muscle group display styles */
  .col.muscle-group {
    width: 100%;
    grid-template-columns: 1fr;
    margin-top: 0.5rem;
  }

  .muscle-group-display {
    width: 80%;
    justify-content: center;
    text-align: center;
  }

  /* Update form actions and buttons for mobile */
  .form-actions {
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 0.5rem;
  }

  .workout-submit-btn,
  .add-exercise-btn,
  .complete-btn,
  .add-pr-btn {
    min-width: auto;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    flex: 0 1 auto;
  }

  .react-datepicker-popper,
  .react-datepicker-wrapper,
  .react-datepicker__portal {
    z-index: 1003 !important;
  }
}

/* Create a new stacking context for the workout form */
.workout-form {
  position: relative;
  z-index: 1;
}

/* Override the date picker styles specifically for mobile */
.date-picker-container {
  position: relative;
  z-index: 1002;
}

.react-datepicker-popper,
.react-datepicker-wrapper,
.react-datepicker__portal {
  z-index: 1003 !important;
}

/* Make exercise dropdown highest priority */
.workout-table .workout-row .col.exercise .exercise-dropdown {
  z-index: var(--z-exercise-dropdown);
  position: absolute;
}

/* Ensure active row is above date picker but below dropdown */
.workout-table .workout-row.active {
  z-index: var(--z-dropdown);
}

/* Button Base Styles */
.workout-form .add-exercise-btn,
.workout-form .add-pr-btn,
.workout-form .complete-btn,
.workout-form .workout-submit-btn {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: background-color 0.2s;
  color: white;
  min-width: 120px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Add Exercise Button (Blue) */
.workout-form .add-exercise-btn {
  background-color: var(--add-exercise-color) !important;
}

.workout-form .add-exercise-btn:hover {
  background-color: var(--add-exercise-hover) !important;
}

/* Add PR Button (Purple) */
.workout-form .add-pr-btn {
  background-color: var(--add-pr-color) !important;
}

.workout-form .add-pr-btn:hover {
  background-color: var(--add-pr-hover) !important;
}

/* Mark Complete Button (Green) */
.workout-form .complete-btn {
  background-color: var(--complete-color) !important;
}

.workout-form .complete-btn:hover:not(:disabled) {
  background-color: var(--complete-hover) !important;
}

/* Save Workout Button (Red) */
.workout-form .workout-submit-btn {
  background-color: var(--save-color) !important;
}

.workout-form .workout-submit-btn:hover:not(:disabled) {
  background-color: var(--save-hover) !important;
}

/* Disabled state for buttons */
.workout-form .complete-btn:disabled,
.workout-form .workout-submit-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Base button styles */
button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: background-color 0.2s;
  color: white;
  min-width: 120px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Add Exercise Button (Blue) */
button.add-exercise-btn {
  background-color: #4299e1 !important;
}

button.add-exercise-btn:hover {
  background-color: #3182ce !important;
}

/* Add PR Button (Purple) */
button.add-pr-btn {
  background-color: #9f7aea !important;
}

button.add-pr-btn:hover {
  background-color: #805ad5 !important;
}

/* Mark Complete Button (Green) */
button.complete-btn {
  background-color: #48bb78 !important;
}

button.complete-btn:hover:not(:disabled) {
  background-color: #38a169 !important;
}

/* Disabled state */
button.complete-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Form actions container */
.form-actions {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

/* Add these mobile styles at the bottom of your Workout.css */
@media screen and (max-width: 480px) {
  /* Container spacing */
  .workout-container {
    padding: 0.5rem;
    margin: 0.5rem;
  }

  /* Form controls */
  .form-group {
    margin-bottom: 0.5rem;
  }

  .form-group label {
    font-size: 0.85rem;
    margin-bottom: 0.25rem;
  }

  .form-group input,
  .form-group select {
    padding: 0.5rem;
    font-size: 0.85rem;
  }

  /* Exercise rows */
  .exercise-row {
    gap: 0.25rem;
    padding: 0.25rem 0;
    margin-bottom: 0.25rem;
  }

  .exercise-row input,
  .exercise-row select {
    padding: 0.35rem;
    font-size: 0.85rem;
  }

  /* Button container */
  .form-actions {
    gap: 0.5rem;
    margin-top: 0.75rem;
    flex-wrap: wrap;
    justify-content: center;
  }

  /* Make buttons smaller */
  button {
    padding: 0.5rem 0.75rem;
    font-size: 0.8rem;
    min-width: auto;
  }

  .add-exercise-btn,
  .add-pr-btn,
  .complete-btn,
  .workout-submit-btn {
    padding: 0.5rem 0.75rem;
    font-size: 0.8rem;
    min-width: auto;
    flex: 1 1 calc(50% - 0.25rem); /* Two buttons per row */
  }

  /* Notes section */
  .workout-notes {
    margin-top: 0.75rem;
  }

  .workout-notes h4 {
    font-size: 0.9rem;
    margin-bottom: 0.25rem;
  }

  .notes-textarea {
    padding: 0.5rem;
    font-size: 0.85rem;
    min-height: 60px;
  }

  /* Client selection area */
  .client-search {
    margin-bottom: 0.5rem;
  }

  /* Exercise selection area */
  .exercise-selection {
    margin-bottom: 0.5rem;
  }

  /* PR container if you have one */
  .pr-container {
    padding: 0.5rem;
    margin: 0.5rem 0;
  }
}

/* Establish a clear z-index hierarchy */
:root {
  --z-base: 1;
  --z-dropdown: 1000;
  --z-datepicker: 1100;
  --z-exercise-dropdown: 1200;
}

/* Base layout z-index */
.workout-form {
  position: relative;
  z-index: var(--z-base);
}

/* Client dropdown */
.client-dropdown {
  z-index: var(--z-dropdown);
}

/* Date picker */
.react-datepicker-popper,
.react-datepicker-wrapper,
.react-datepicker__portal {
  z-index: var(--z-datepicker) !important;
}

/* Exercise dropdown - highest priority */
.exercise-dropdown {
  z-index: var(--z-exercise-dropdown);
}

.workout-row.active .col.exercise {
  z-index: var(--z-exercise-dropdown);
}
